<template>
  <b-card
    border-variant="dark"
    bg-variant="transparent"
    class="shadow-none mx-1 p-2"
  >
      <!-- :value="modelValue" -->
    <b-form-checkbox
      v-model="isChecked"
      @change="$emit('update:isCheckdd', $event.target.value)"
    >
      {{ isBot ? `${platformName} Bot` : platformName }}
    </b-form-checkbox>
  </b-card>
</template>

<script>
export default {
  props: {
    platformName: {
      type: String,
      default: null,
    },
    isBot: {
      type: Boolean,
      default: false,
    },
    initialState: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isChecked: false,
      modelValue: false,
    }
  },
  created() {
    this.isChecked = this.initialState
    this.modelValue = this.initialState
  },
}
</script>

<style lang="scss" scoped>

</style>

